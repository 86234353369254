import React from "react"
import AuthorImage from "./components/AuthorImage"

export default function DaZeroAlBrand() {
  return (
    <section className="section-cover">
      <div className="container">
        <div className="columns is-centered px-5">
          <div className="column is-four-fifths">
            <div className="columns is-widescreen">
              <div className="column is-one-third">
                <h2>RETRO<br />MARKETING</h2>
                <h3 className="mt-2">Il Marketing strategico tra nostalgia e innovazione</h3>
                <p className="mt-6"><a
                  rel="noreferrer" target="_blank"
                  href="https://www.amazon.it/marketing-marketing-strategico-nostalgia-innovazione/dp/B0BG8H5JVM/ref=sr_1_2?qid=1670337044&refinements=p_27%3ADaniela+Bavuso&s=books&sr=1-2">Acquista</a>
                </p>
              </div>
              <div className="column book-cover">
                <AuthorImage fileName="cover.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
